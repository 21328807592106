
import { defineComponent, reactive } from 'vue';
import RetrieveModal from '@/components/retrieve/RetrieveModal.vue';
import { useInject } from '@/utils/inject';

export default defineComponent({
  name: 'Test',
  components: { RetrieveModal },
  setup() {
    const { https, mapper } = useInject();

    const retrieveOpts = reactive({
      title: '查找',
      visible: false,
      content: {
        search: {
          criteria: {
            cols: [
              {
                field: 'alias',
                label: '用户名'
              }
            ]
          },
          remote: {
            action: https?.sys.pageUser
          }
        },
        cols: [
          {
            field: 'uid',
            label: 'UID'
          },
          {
            field: 'alias',
            label: '用户名'
          },
          {
            field: 'name',
            label: '姓名'
          },
          {
            field: 'phone',
            label: '手机号'
          },
          {
            field: 'deptName',
            label: '部门'
          },
          {
            field: 'roleName',
            label: '职位'
          },
          {
            field: 'status',
            label: '状态',
            options: mapper?.common.enableStr
          }
        ],
      }
    });

    const onSelect = (data: any) => {
      console.log('onselect..', data);
    };
    return { retrieveOpts, onSelect };
  }
});
