import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_retrieve_modal = _resolveComponent("retrieve-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.retrieveOpts.visible = true))
    }, {
      default: _withCtx(() => [
        _createTextVNode("查找")
      ]),
      _: 1
    }),
    _createVNode(_component_retrieve_modal, {
      opts: _ctx.retrieveOpts,
      onSelect: _ctx.onSelect
    }, null, 8, ["opts", "onSelect"])
  ]))
}